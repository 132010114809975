<template>
  <div class="col-auto row imd-q-file items-start">

<!--    {{ value }}-->

    <img v-if="file && $refs.uploader && $refs.uploader.files && $refs.uploader.files[0] && $refs.uploader.files[0].__img"
         :src="$refs.uploader.files[0].__img.src"
         style="max-height: 120px;"
         @click="popup($refs.uploader.files[0])"
         class="rounded-borders cursor-pointer"
    >

    <div v-if="file && $refs.uploader  && $refs.uploader.files" class="col-auto column q-mr-xs">
      <!--      <q-icon size="md" name="clear"        @click="clear" v-if="$refs.uploader.files.length"/>-->
      <q-icon size="md" name="rotate_left"  @click="doRotate($refs.uploader.files[0], 'left')"  class="cursor-pointer" v-if="$refs.uploader.files.length"/>
      <q-icon size="md" name="rotate_right" @click="doRotate($refs.uploader.files[0], 'right')" class="cursor-pointer" v-if="$refs.uploader.files.length"/>
      <q-icon size="md" name="clear"        @click="clear" class="cursor-pointer" v-if="$refs.uploader.files.length"/>
      <!--      <i-bar-btn title="업로드" @click="$refs.uploader.upload" v-if="$refs.uploader.canUpload" />-->
    </div>

    <div class="column col-auto">
      <q-file
        :value="file"
        @input="selectFile"
        label="이미지: 클릭 또는 Drang & Drop"
        filled
        style="min-width: 310px;"
        _input-style="width: 300px; height: 300px;"
        class="col-auto"
        clearable
      >
        <template v-slot:prepend>
          <q-icon name="image" />
        </template>
      </q-file>
      <q-linear-progress :value="$refs.uploader.uploadProgress" color="accent" v-if="$refs.uploader"/>
    </div>

    <q-uploader
      v-show="false"
      ref="uploader"
      :batch="false"
      :multiple="false"
      with-credentials
      url="/v1/api/upload"
      :hide-upload-btn="false"

      class="imd-q-uploader col-auto"
      style=""

      @added     = "onAdded"
      @removed   = "onRemoved"
      @uploading = "onUploading"
      @uploaded  = "onUploaded"
      @finish    = "v=>T.log('finish : ' + JSON.stringify(v))"
      :auto-upload="false"
    >
      <template v-slot:header="scope">
        <q-uploader-add-trigger /><!-- 이 트리거가 있어야 pickFile() 함수가 작동한다. -->
      </template>
      <template v-slot:list="scope">
      </template>
    </q-uploader>

    <!-- 이미지 크게보기   -->
    <q-dialog v-model="modal_imageViewer"
              v-if="sitem"
              full-width square
              transition-show="none" transition-hide="none"
    >
      <q-card class="no-scroll fit">
        <q-img contain :src="sitem.__img.src" class="bg-grey-7 fit">
          <div class="text-subtitle2 absolute-top text-right q-gutter-sm">
            <i-bar-btn title="왼쪽으로 회전"   @click="doRotate(sitem, 'left')"/>
            <i-bar-btn title="오른쪽으로 회전" @click="doRotate(sitem, 'right')"/>
            <!--            <i-bar-btn title="저장"/>-->
            <i-bar-btn title="닫기(ESC)" @click="modal_imageViewer=false"/>
          </div>
        </q-img>
      </q-card>
    </q-dialog>


  </div>
</template>

<script>

import {ref, reactive, toRefs, onBeforeMount, onMounted, computed, watch, getCurrentInstance,} from 'vue';
// https://v3.vuejs.org/guide/composition-api-introduction.html#reacting-to-changes-with-watch

import { imageResize, imageRotate } from 'lib/img_utils' //  https://github.com/blueimp/JavaScript-Load-Image

export default {
  inheritAttrs: false,
  props: {
    value     : { type: Object },
    autoUpload: { type: Boolean, default: false,},
  },
  setup(props, {emit}) {

    let _vm = null;
    let _uploader = null;

    onMounted(()=>{
      _vm = getCurrentInstance();
      _uploader = _vm.proxy.$refs.uploader;
      // console.log("vm = ", _vm);
      // console.log("_uploader = ", _uploader);
    })

    watch(() => props.value, (newValue, oldValue) => {
      // console.log("oldValue = ", oldValue)
      // console.log("newValue = ", newValue)
      if (newValue === undefined) { // 외부에서 날린경우
        data.file = null;
        _uploader.reset();
        _uploader.removeUploadedFiles();
        _uploader.removeQueuedFiles();
      }
    })

    const defaultEmitFile = {
      name: null,
      saved_filename: null,
      ext: null,
      type: null,
      status: 'ready', // ready, ing, ok, error
      msg: '', // 업로드 실패 오류 메세지
      // type: "image/jpeg"
      // ext: "jpg"
      // name: "2021-08-01 14.21.32.jpg"
      // saved_filename: "8188_20210906112717429lwu7tdml.jpg"
      // size: 1000950
      // status: "ok"
    }
    const emitFile = _.clone(defaultEmitFile)

    const data = reactive({
      // list: [],
      modal_imageViewer: false,
      sitem: null, // 선택한 이미지 ( 이미지뷰에서 사용 )
      metaCopy: true, // 메타정보 복사여부
      file: null, // 파일하나
      defaultEmitFile, emitFile,
    })

    // 파일 교체
    function replaceFile(oldFile, newFile) {
      oldFile.no_remove = true;
      _uploader.removeFile(oldFile); // 이걸하면  onRemoved가 자동으로 실행되기에 oldFile.no_remove = true;
      _uploader.reset();
      newFile.no_add = true;
      _uploader.addFiles([newFile]); // 이걸하면 onAdded가 자동으로 실행되기에 newFile.no_add = true;
    }

    const actions = {

      selectFile: file => {
        data.file = file;
        _uploader.reset();
        _uploader.removeUploadedFiles();
        _uploader.removeQueuedFiles();
        if (file) { // 파일을 선택한 경우 ( clear 한 경우엔 file 이 null 이다 )
          _uploader.addFiles([file]);
        } else { // 클리어한 경우
          data.emitFile = _.clone(defaultEmitFile);
          // emit("input", data.emitFile) // (성공) 변수값 설정
          emit("input", null) // 클리어한 경우 외부에는 null 로 보고한다.
        }
      },

      clear() {
        data.file = null;
        data.emitFile = _.clone(defaultEmitFile);
        _uploader.reset();
        _uploader.removeUploadedFiles();
        _uploader.removeQueuedFiles();
        emit("input", null) // 클리어한 경우 외부에는 null 로 보고한다.
      },

      // 파일이 추가된 경우
      async onAdded(files) {
        // 교체로 인하여 추가된 경우 아무것도 안함.
        if (files[0].no_add) {
          return (delete files[0].no_add);
        }

        // 추가 및 자동 리사이즈
        // 현재 제일 큰 번호
        for(const file of files) {
          file.resized = false;
          console.log("add file = ", file)
          const emitFile = data.emitFile
          try {

            let ret = file
            emitFile.name   = ret.name
            emitFile.saved_filename = undefined
            emitFile.size           = ret.size
            emitFile.ext            = (/[.]/.exec(ret.name)) ? /[^.]+$/.exec(ret.name)[0] : undefined
            emitFile.type   = ret.type
            emitFile.status         = 'added' // ready, added, resized, ok, error업로드 실패 오류 메세지
            emitFile.msg            = ''        // 상태 메세지
            try {
              const resizedFile = await imageResize(file, data.metaCopy);
              replaceFile(file, resizedFile) // 업로더의 파일 교체
              emitFile.size           = resizedFile.size
              emitFile.status         = 'resized' // ready, resized, ok, error업로드 실패 오류 메세지
              emitFile.msg            = ''        // 상태 메세지
              emit("input", emitFile) // (성공) 변수값 설정
            } catch(e) {
              emitFile.status = 'error'
              emitFile.msg = e
              emit("input", emitFile) // (실패) 변수값 설정
              throw e;
            }

            if (props.autoUpload) { // 자동 업로드인 경우
              _uploader.upload();
            }
          } catch(e) {
            alert("이미지 리사이즈 오류\n\n"+ e);
          }
        }
      },

      // 파일이 제거된 경우
      // onRemoved: removedFiles => removedFiles[0].no_remove // 교체로 인한 삭제시
      //   ? (delete removedFiles[0].no_remove) // 아무것도 안함
      //   : removedFiles.forEach(file => delete data.sortMap[file.name]),
      onRemoved: v => T.log("파일제거됨 :", v),

      // 이미지 상세보기 모달 호출
      popup: item => {data.sitem = item; data.modal_imageViewer = true},

      // 이미지 회전
      doRotate: async (file, rotate) => replaceFile(file, data.sitem = await imageRotate(file, rotate, data.metaCopy)),

      onUploading(info) {
        console.log("업로드 시작 :", info)
      },

      doUpload: () => {
        _uploader.upload();
      },

      onUploaded(v) {  // 업로드 완료
        // console.log("업로드 종료 = ", v);
        // console.log("업로드 종료 = ", v.xhr);
        // console.log("업로드 종료 = ", v.xhr.response);
        const ret = JSON.parse(v.xhr.response);
        // console.log("ret = ", ret)
        // _vm.$emit("")

        // type: "image/jpeg"
        // ext: "jpg"
        // name: "2021-08-01 14.21.32.jpg"
        // saved_filename: "8188_20210906112717429lwu7tdml.jpg"
        // size: 1000950
        // status: "ok"
        const emitFile = data.emitFile
        emitFile.saved_filename = ret.saved_filename
        emitFile.size   = ret.size
        emitFile.status = "ok"
        emitFile.msg    = ""
        emit("input", emitFile) // 변수값 설정
        emit("uploaded", emitFile) // 업러드 완료 이벤트
      },
    }
    return {...toRefs(data), ...actions,}
  }
}
</script>

