import {defineStore} from 'pinia'
import {LoginStatus, UserInfo} from "@/types/login";
import {ref} from "vue";
import {LoginResponse} from "lib/co-login";

// export const useSessionStore
//   = defineStore('session', () => {
//  
//   const status = ref<LoginStatus>('notLogin')
//   const info = ref<UserInfo | null>(null)
//  
//   const loginStatus = ref<LoginResponse | null>(null)
//  
//   return {
//     status, info, loginStatus
//   }
// })


export const useSessionStore = defineStore('session', {

  // https://pinia.vuejs.kr/core-concepts/state.html#TypeScript
  state: () => ({
    loginStatus: null as LoginResponse | null,
    loginLastGet: 0 as number,
  }),
  getters: {
    isLogin: (state) => state.loginStatus?.login,
    // doubleCount: (state) => state.count * 2,
    dp: (state) => {
      const user = state?.loginStatus?.user
      return user
        ? `${user.name}(${user.id})`
        : '로그인 안됨'
    }
      
  },
  actions: {
    // increment() {
    //   this.count++
    // },
  },
})
