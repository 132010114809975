import Vue from 'vue'

// node make_index.js

import BrSm           from './br-sm.vue'             ; Vue.component('br-sm'             , BrSm           );
import IBarBtn        from './i-bar-btn.vue'         ; Vue.component('i-bar-btn'         , IBarBtn        );
import IBarIcon       from './i-bar-icon.vue'        ; Vue.component('i-bar-icon'        , IBarIcon       );
import IBarWin        from './i-bar-win.vue'         ; Vue.component('i-bar-win'         , IBarWin        );
import IBar           from './i-bar.vue'             ; Vue.component('i-bar'             , IBar           );
import IBtn           from './i-btn.vue'             ; Vue.component('i-btn'             , IBtn           );
import ICheckbox      from './i-checkbox.vue'        ; Vue.component('i-checkbox'        , ICheckbox      );
import IInputChk      from './i-input-chk.vue'       ; Vue.component('i-input-chk'       , IInputChk      );
import IInputDate     from './i-input-date.vue'      ; Vue.component('i-input-date'      , IInputDate     );
import IInputImg      from './i-input-img.vue'       ; Vue.component('i-input-img'       , IInputImg      );
import IInputImg_org  from './i-input-img_org.vue'   ; Vue.component('i-input-img_org'   , IInputImg_org  );
import IInputOneImg   from './i-input-one-img.vue'   ; Vue.component('i-input-one-img'   , IInputOneImg   );
import IInputRadioOne from './i-input-radio-one.vue' ; Vue.component('i-input-radio-one' , IInputRadioOne );
import IInputRadio    from './i-input-radio.vue'     ; Vue.component('i-input-radio'     , IInputRadio    );
import IInputSel      from './i-input-sel.vue'       ; Vue.component('i-input-sel'       , IInputSel      );
import IInput         from './i-input.vue'           ; Vue.component('i-input'           , IInput         );
import ITdBtn         from './i-td-btn.vue'          ; Vue.component('i-td-btn'          , ITdBtn         );
import MUploader      from './m-uploader.vue'        ; Vue.component('m-uploader'        , MUploader      );
import SAttachFiles   from './s-attach-files.vue'    ; Vue.component('s-attach-files'    , SAttachFiles   );
import SBody          from './s-body.vue'            ; Vue.component('s-body'            , SBody          );
import SBtnGroup1     from './s-btn-group-1.vue'     ; Vue.component('s-btn-group-1'     , SBtnGroup1     );
import SChip          from './s-chip.vue'            ; Vue.component('s-chip'            , SChip          );
import SCode          from './s-code.vue'            ; Vue.component('s-code'            , SCode          );
import SContent       from './s-content.vue'         ; Vue.component('s-content'         , SContent       );
import SEditor        from './s-editor.vue'          ; Vue.component('s-editor'          , SEditor        );
import SHelp          from './s-help.vue'            ; Vue.component('s-help'            , SHelp          );
import SIcon          from './s-icon.vue'            ; Vue.component('s-icon'            , SIcon          );
import SIeWarn        from './s-ie-warn.vue'         ; Vue.component('s-ie-warn'         , SIeWarn        );
import SMiniTable     from './s-mini-table.vue'      ; Vue.component('s-mini-table'      , SMiniTable     );
import SPageAutoTitle from './s-page-auto-title.vue' ; Vue.component('s-page-auto-title' , SPageAutoTitle );
import SPageTitle     from './s-page-title.vue'      ; Vue.component('s-page-title'      , SPageTitle     );
import SSimpleTable   from './s-simple-table.vue'    ; Vue.component('s-simple-table'    , SSimpleTable   );
import SSplitSimple   from './s-split-simple.vue'    ; Vue.component('s-split-simple'    , SSplitSimple   );
import SSplit         from './s-split.vue'           ; Vue.component('s-split'           , SSplit         );
import STdWrapmd      from './s-td-wrapmd.vue'       ; Vue.component('s-td-wrapmd'       , STdWrapmd      );
import STd            from './s-td.vue'              ; Vue.component('s-td'              , STd            );
import STooltip       from './s-tooltip.vue'         ; Vue.component('s-tooltip'         , STooltip       );
import STr            from './s-tr.vue'              ; Vue.component('s-tr'              , STr            );
import SWrapper       from './s-wrapper.vue'         ; Vue.component('s-wrapper'         , SWrapper       );



import STable         from './stable/s-table.vue'     ; Vue.component('s-table'     , STable     )
import SDragTable     from './stable/s-drag-table.vue'; Vue.component('s-drag-table', SDragTable )
