<template>
  <i-input
    w="112"
    v-bind="{ ...$attrs, value: value_cp, }"
    v-on="{
       ...$listeners,
       input: v => emit_value(value_cp = v)
     }"
    mask="####-##-##" fill-mask="_" clearable
    class="imd-i-input-date"
  >
    <!--    :value="value_cp"-->
    <template v-slot:after>
      <q-icon name="event" class="cursor-pointer">
        <q-popup-proxy
          ref="qDateProxy"
          transition-show="jump-down" transition-hide="jump-up"
          @before-show="proxyDate = value_cp"
          class="no-shadow"
        >

          <div>
            <q-date
              minimal
              today-btn
              mask="YYYY-MM-DD"
              :value="proxyDate"
              @input="save"
              class="imd-q-date no-shadow"
              flat style="border: 1px solid gray"
            >
              <!--              <div class="row items-center justify-end q-gutter-sm">-->
              <!--                <q-btn label="Cancel" color="primary" flat v-close-popup />-->
              <!--                <q-btn label="OK"     color="primary" flat v-close-popup @click="save" />-->
              <!--              </div>-->
            </q-date>
          </div>
        </q-popup-proxy>
      </q-icon>
    </template>
  </i-input>
</template>

<script>
const masked_date_string = '____-__-__'
function unmaskd_value(v) {
  if (T.isNU(v) || v === masked_date_string) return '' // v 가 없거나 마스크랑 완전 동일하면 그대로 날림
  if (masked_date_string.length === v.length) { // 마스크랑 길이가 같은경우
    let i = v.length - 1
    while(masked_date_string[i] === v[i]) i--;  // 뒤에서 부터 마스크랑 같은 부분을 하나씩 체크해서
    if (i < v.length -1)                        // 같은 부분이 있다면
      return v.substring(0, i + 1)              // 같은 부분을 잘라버린다. ex) "2021-11-__" --> "2021-11"
  }
  return v // 마스크랑 길이가 다르거나 잘라진것도 없으면 원본 그대로 반환
}

export default {
  inheritAttrs: false,
  props: {
    value: {}
  },
  data () {
    return {
      value_cp : null,
      proxyDate : null,
    }
  },
  computed: {
    // calcPickerDate() {
    //   if (!this.value_cp) return "";
    //   const v = this.$dayjs(this.value_cp, 'YYYY-MM-DD').format('YYYY-MM-DD')
    //   return v == "Invalid date" ? "" : v
    // },
    v_id() {
      return this.$vnode.data.model && 'input-'+this.$vnode.data.model.expression || 'v'
    }
  },
  watch: {
    value(n,p) {
      this.value_cp = this.proxyDate = this.value
    }
  },
  created () {
    // console.log("this.value = ", this.value);
    this.value_cp = this.value
    // this.proxyDate = this.value
  },
  mounted() {
    if (this.value === undefined) {
      this.emit_value('')
    }
  },
  methods: {
    save(v) {
      if (T.isNotNU(v)) this.proxyDate = v // 설정한 날자가 동일한 날자인경우 null을 반환하기 때문에(왜지 ??) NULL 이 아닐때만 인식하게 한다.
      this.emit_value(this.value_cp = this.proxyDate)
      this.$refs.qDateProxy.hide()
    },
    emit_value(v) {
      this.$emit('input', unmaskd_value(v))
    }
  }
}
</script>
