<!-- tinymce 웹데이터 컴포넌트 랩핑 -->
<!--suppress JSUnusedGlobalSymbols -->
<template>
  <!--  https://www.tiny.cloud/docs/integrations/vue/ -->
  <span class="s-editor full-width"><!-- 에디터 스타일 지정을 위해서 class 를 지정해야 하는데 바깥에서만 먹는다 -->
    <TinyMce
      style="width: 100%"
      ref="ref_tiny"
      v-bind="$attrs"
      v-on="$listeners"
      api-key="self-host"
      :init="myInit"
    />
  </span>
</template>
<script lang="ts">
import TinyMce from '@tinymce/tinymce-vue'

import {tinymce_default_init} from "./editor/editor-config"
import {editor_image_handler} from "./editor/editor-image-handler";
import {Editor, RawEditorOptions} from "tinymce";

export default {
  components: {TinyMce},
  inheritAttrs: false,
  props: {
    height: {},
    placeholder: {type: String},
    // value: {type: String},
    // autoFocus: {type: Boolean, default: false},
  },
  data() {
    return {
      editor: null, // 에디터 인스턴스를 저장할 변수
      // @ts-ignore
      myInit: this.getMyInit(),
    }
  },
  mounted() {
    // setTimeout(()=> {
    //   // -- execCommand :         https://www.tiny.cloud/docs/tinymce/6/apis/tinymce.root/#execCommand
    //   // -- execCommand 종류 :    https://www.tiny.cloud/docs/tinymce/6/editor-command-identifiers/
    //   // -- editor 매서드 종류 :  https://www.tiny.cloud/docs/tinymce/6/apis/tinymce.html.writer/#getContent
    //   // this.$refs.ref_tiny.editor.execCommand("mceInsertContent",'false',"<div>qqqqqqqqqqqqqqqqq1123</div>");
    //   const ctx = this.$refs.ref_tiny.editor.getContent()
    //   console.log(`[Editor] getContent = ${ctx}`)
    // }, 5000)
  },
  methods: {
    getMyInit(): any {
      const newInit: RawEditorOptions = {
        ...tinymce_default_init,
        ...editor_image_handler,
        setup: (editor: Editor) => { // https://www.tiny.cloud/docs/configure/integration-and-setup/#setup
          this.editor = editor // 에디터 인스턴스를 저장
          editor_image_handler.setup(editor)
          // https://www.tiny.cloud/docs/ui-components/typesoftoolbarbuttons/#basicbuttonexampleandexplanation
          editor.on("keyup", e=> {
            // 크롬/macOS 에서 Backspace, Delete 키는 @input 이 발생되지 않아서 수동으로  발생시킴
            if (e.key === "Backspace" || e.key === "Delete") {
              console.log("*** [tiny editor keyup] ----- ", e.key)
              this.update();
            }
          })
          editor.on("keydown", e=> {
            if (e.key === 's' && e.metaKey) {
              e.preventDefault();  // 기본 동작 방지
              // console.log('Command + S가 감지되었습니다.');
              this.$emit('save', this.getContent())
            }
          })
        },
        // save_onsavecallback: () => { this.$emit('save', this.getContent()) },
      }
      if (this.height     ) newInit.height = this.height
      if (this.placeholder) newInit.placeholder = this.placeholder
      return newInit as any
    },
    setContent: function() {
      this.$refs.ref_tiny.editor.setContent(this.value)
    },
    getContent: function() {
      return this.$refs.ref_tiny.editor.getContent()
    },
    update: function() {
      this.$emit('input', this.getContent())
    },
    uploadImages: async function() { // imagetools 등에서 보류중이던 DataURI 이미지를 서버로 업로드
      await this.editor?.uploadImages()
      this.update()
    },

  }
}

</script>


<style scoped>
/* tinymce v7 스킨 조정 */

/* 전체 radius */
.s-editor >>> .tox.tox-tinymce {border-radius: 2px !important;}

/* 상단 툴바 */
.s-editor >>> .tox:not(.tox-tinymce-inline) .tox-editor-header {padding: 1px !important;} /* 툴바 기본 padding */
.s-editor >>> .tox .tox-toolbar__primary {padding-left: 1px !important;} /* 툴바 첫번째 버튼의 여백 */
.s-editor >>> .tox .tox-toolbar__group   {padding: 0 !important;} /* 툴바 그룹간 padding */
.s-editor >>> .tox .tox-tbtn             {margin: 0 !important;} /* 툴바 버튼들의 마진 */
.s-editor >>> .tox .tox-split-button     {margin: 0 !important;} /* 툴바중 split 버튼 (색상버튼) 들의 마진 */

/* 본문에 상단 그림자 없애고 그냥 한줄 */
.s-editor >>> .tox:not(.tox-tinymce-inline) .tox-editor-header {
  border-bottom: 1px solid #EEEEEE !important ;
  box-shadow: none !important;
}

/* 하단 상태바 */
.s-editor >>> .tox .tox-statusbar { font-size: 12px !important; height: 14px !important; border-top: none !important; } /* 하단 상태바  */
.s-editor >>> .tox .tox-statusbar .tox-statusbar__resize-handle { margin: 0 -8px 0 8px !important; } /* 하단 상태바 리사이즈 핸들  */ 


</style>

<style>
/* tinymce 툴팁 끄고 싶을때 - 이건 scoped가 안먹는다. 즉 전역으로만 사용 가능 ㅠㅠ */
/*.tox .tox-tooltip { display: none !important; }*/
</style>
