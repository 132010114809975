// noinspection DuplicatedCode

import loadImage from 'blueimp-load-image' //  https://github.com/blueimp/JavaScript-Load-Image
import {Editor, RawEditorOptions} from "tinymce";
import {UploadImageFileType, imageServerUpload} from "./editor-image-uploader";
// import Swal from 'sweetalert2'

// 관련 정리 - https://wiki.imdgo.com/pages/viewpage.action?pageId=164300355
const editor_image_max_width = 1440;  // 이미지 리사이즈 최대 너비
const editor_image_use_DataURI = true; // 이미지툴스를 제외한 툴바버튼, 붙여넣기, 본문드롭시 Data URI 로 추가할지 여부
// ㄴ 이걸을 false로 하면 즉시 서버업로드하여 <imd src 가 서버주소가 된다
//    1. 하지만 true로 하더라도 automatic_uploads: true인 경우 images_upload_timeout 시간이 지나면
//       자동으로 images_upload_handler가 호출되어 서버로 업로드 되어 마찬가지로 <imd src 가 서버주소가 된다
//    2. automatic_uploads: false 이거나 images_upload_timeout 시간이 매우 길더라도
//       에디터 외부에서 폼 전송시 editor?.uploadImages() 를 호출해도 마찬가지로 업로드 되면서 서버 주소로 바뀐다.
//    그리고 어차피 이미지툴스 기능(회전,반전,편집) 사용시 무조건 Data URI 로 변형되기 떄문에
//    그냥 editor_image_use_DataURI = true 로 하여 전체적으로 관리를 통일하는게 좋은 것 같다.
//    * 하지만 안정성 면에서는 false 로 하는게 좋을 수도 있기에 일단 true로 해 놓고 문제가 있을떄만 false를 고려하자.

export const editor_image_handler: RawEditorOptions = {
  
  // -- 이미지 업로드 관련 init 설정
  editimage_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions', // - https://www.tiny.cloud/docs/tinymce/latest/editimage/
  //  ㄴ 만약 automatic_uploads 가 true 이고 images_upload_timeout 이 짧은경우 경우 editimage imageoptions 만 넣는게 좋다. 
  // 왜냐하면 그 상태에선 회전버튼 하나만 눌러도 바로바로 서버 업로드 된다.

  images_upload_timeout: 1000, // 업로드 대기 시간(ms). automatic_uploads: true, 일때만 사용된다.
  automatic_uploads: true,     // 자동 업로드 - Data URI 로 되어있는걸 자동으로 업로드 한다.
  //                              이 값을 false 로 하면 업로드시 바로바로 수동으로 업로드 하거나 Data URI 로 그냥 쓰거나(비추)
  //                              본문 제출시 editor?.uploadImages() 를 호출해서 업로드 해야한다.
  
  // 기본값으로 automatic_uploads: true에 images_upload_timeout: 1초로 하고
  // 만약 외부에서 폼 제출시 editor?.uploadImages() 를 호출하는 방식으로 코딩을 하는 경우
  // s-editor 호출시 automatic_uploads: false 로 하거나 images_upload_timeout 시간을 길게해서 사용하자.
  
  // 이미지 없로드시 src 경로가 변조되지 않도록 설정 (이걸 안하면 앞에 ../.. 같은게 붙는다)
  relative_urls: false, // 절대 경로를 사용하도록 설정
  remove_script_host: true, // 도메인 제거
  paste_data_images: true, // 이미지 붙여넣기를 허용한다.
  // document_base_url: '/',

  /**
   * 이미지 업로드 처리 (tinymce 기본) - 본문에 Data URI 형태의 이미지들을 서버로 업로드 한다.
   * - 가령 이미지툴스(imagetools)편집 완료시 무조건 Data URI 상태가 되어 버리는데 이때
   *   automatic_uploads: true, images_upload_timeout: 1000 설정시 1초 있다가 자동으로 이게 호출되면서
   *   서버 업로드가 되고 Data URI 는 서버 주소로 자동으로 변경된다. (또는 editor?.uploadImages() 실행시에도 호출된다)
   * - 그 외에 editor_image_use_DataURI = true 로 하면 툴바버튼, 붙여넣기, 본문드롭시 Data URI 로 추가되어
   *   이 기본 함수(images_upload_handler)가 호출된다.
   */
  images_upload_handler: async (blobInfo, progress) => {
    const blob = blobInfo.blob()
    const fitem: UploadImageFileType = {
      // 이미지 툴로 편집된경우 blobInfo.filename() 으로만 이름을 구할 수 있다. 보통 "imagetools1.png" 이다.
      name: blobInfo.filename instanceof Function ? blobInfo.filename() :
            blobInfo.name     instanceof Function ? blobInfo.name()     : undefined,
      size: blob.size ?? 0,
      type: blob.type ?? '',
      blob: blob,
    }
    if (!fitem.name && fitem.type) { // 이름이 없는 경우
      fitem.name = "blob." + fitem.type.split(".")
    }
    return await imageServerUpload(fitem);
  },
  setup: (editor: Editor) => { // https://www.tiny.cloud/docs/configure/integration-and-setup/#setup
    // https://www.tiny.cloud/docs/ui-components/typesoftoolbarbuttons/#basicbuttonexampleandexplanation

    // -----------------------------------------------------------------------------------------------------------------
    // -- 이미지 업로드 툴바 버튼 처리
    // -----------------------------------------------------------------------------------------------------------------
    
    editor.ui.registry.addButton("image-imd", { // text: "이미지",
      icon: "image", tooltip: "이미지를 업로드 합니다", onAction: () => {
        editor.windowManager.open({
          title: "이미지 업로드",
          body: {
            type: "panel",
            items: [ // {type: 'input'   , name: 'alt_text'  , label: 'alt 문자열 입력'},
              {type: "dropzone", name: "fileinput", label: ""},
            ],
          },
          buttons: [ // {type: 'submit', name: 'submitButton', text: 'save', primary: true}
            {type: 'cancel', name: 'closeButton', text: 'Cancel'},
          ],
          // initialData: { catdata: 'initial Cat', alt_text: '', },
          async onChange(dialogApi) {
            try {
              dialogApi.block("업로드중...") // 다이알로그 블럭 시작
              const data = dialogApi.getData();
              const files = (data as any)?.fileinput;
              for (const file of files) {
                await imageFileToContentAppend(editor, file);
              }
            } finally {
              dialogApi.unblock()  // 다이알로그 블럭 해제
              dialogApi.close() // 다이알로그 닫기
            }
          },
        });
      },
    })

    // -----------------------------------------------------------------------------------------------------------------
    // -- 이미지 붙여넣기(Paste) 처리 -- https://www.tiny.cloud/docs/advanced/events/
    // -----------------------------------------------------------------------------------------------------------------
    
    editor.on('paste', async (e: ClipboardEvent) => {
      e.preventDefault()
      e.stopPropagation()
      await handleFilePasteOrDrop(editor, e.clipboardData?.items);
    });

    // -----------------------------------------------------------------------------------------------------------------
    // -- 이미지를 본문에 Drop 처리 -- https://www.tiny.cloud/docs/advanced/events/
    // -----------------------------------------------------------------------------------------------------------------
    
    editor.on('drop', async (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation()
      await handleFilePasteOrDrop(editor, e.dataTransfer?.items);
    })
    
  },
}
/** 이미지 붙여넣기하고 본문에 드롭하고 두가지 이벤트 처리 방법이 같아서 이 함수 하나로 같이 처리한다. */
async function handleFilePasteOrDrop(editor: Editor, items: DataTransferItemList | null) {
  if (!items) return;
  const files: File[] = [];
  const nofiles: string[] = [];
  for (let i = 0; i < items.length; i++) {
    if (items[i].kind !== 'file' || items[i].type.indexOf("image") === -1) {
      nofiles.push(items[i].getAsFile().name);
      continue;
    }
    const file = items[i].getAsFile();
    if (file) files.push(file);
    // 여게서 await imageFileToContentAppend(editor, file); 이렇게 하면 안된다.. 첫번쨰꺼 하나만 처리된다.
  }
  // paste 이든 drop 이든 먼저 files 에 파일을 모아놓고 처리해야 한다. 한번에 하니깐 여러개 등록시 하나만 등록된다.
  for (const file of files) {
    await imageFileToContentAppend(editor, file);
  }
  if (nofiles.length > 0) {
    alert(`다음 파일들은 이미지가 아닙니다.\n - ${nofiles.join("\n - ")} `)
  }
}


/** 이미지 파일을 본문에 추가 */
async function imageFileToContentAppend(editor: Editor, file: File) {
 
  // 이미지 리사이즈
  const resizedBlob = await imgResize(file, editor_image_max_width);
  
  // [ 메모리에 추가 ]
  // -- automatic_uploads: true, images_upload_timeout: 1000, 으로 되어 있다면
  // -- 1초 후 images_upload_handler 가 자동으로 호출되어 서버로 업로드 된다.
  // -- 또는 form 제출전에 await editor?.uploadImages() 를 해줘도 업로드 된다.
  // -- 만약 둘다 안한다면 본문에 Data URI 로 추가된다. ( 이건 최악임. 디비가,트래픽,속도 전부 나락감. )
  if (editor_image_use_DataURI) {
    const reader = new FileReader();
    reader.onload = function (event) {
      const id = `blobid${(new Date()).getTime()}` + Math.random().toString(36).substring(2, 6);
      const blobCache = editor.editorUpload.blobCache;
      const base64 = (event.target.result as string).split(',')[1];
      const blobInfo = blobCache.create(id, resizedBlob, base64, file.name, file.name);
      blobCache.add(blobInfo);
      const img_alt = escapeQuot(blobInfo.filename() ?? "attach image");
      insertContent(editor, blobInfo.blobUri(), img_alt);
    };
    reader.readAsDataURL(resizedBlob);
  } else {
  // -- 즉시 서버로 업로드 한다음 본문에 추가
    const fitem: UploadImageFileType = {
      name: file.name,
      size: resizedBlob.size,
      type: resizedBlob.type,
      blob: resizedBlob,
    }
    const img_src = await imageServerUpload(fitem)
    const img_alt = escapeQuot(file.name ?? "attach image");
    insertContent(editor, img_src, img_alt);
  }
}

/** 에디터 본문에 <img 추가 */
function insertContent(editor: Editor, img_src: string, img_alt: string) {
  editor.insertContent(`<p><img src="${img_src}" alt="${img_alt}"/><p/>`);
}

/** img alt 에 들어갈 문자 replace */
function escapeQuot(str: string) {
  if (str === undefined || str === null) return ""
  return str
    .replaceAll('"', '&quot;')
    .replaceAll("'", "\'")
}

/** 이미지 리사이즈 */
function imgResize(file: File, maxWidth: number = 1024): Promise<Blob> {
  return new Promise((resolve, reject) => {
    loadImage(file, function (img: Event | HTMLCanvasElement | HTMLImageElement) {
        if (img instanceof Event) { //  && img.type === "error"
          reject(new Error("이미지 로딩 실패 (ERR-8313)"));
          return;
        }
        try {
          // 리사이즈
          const resizedCanvas = loadImage.scale(img, {
            meta: true, // 필수 옵션
            canvas: true,
            maxWidth: maxWidth,
            orientation: true, // 이걸 true 또는 1로 해야지 회전된 이미지가 제대로 업로드 된다. 
            imageSmoothingEnabled: false,
          });

          resizedCanvas.toBlob(function (blob) {
            if (blob) {
              resolve(blob); // 리사이즈 성공
            } else {
              reject(new Error("이미지 리사이즈 실패 (ERR-8314)"));
            }
          }, file.type);
        } catch (error) {
          reject(new Error("이미지 리사이즈 중 오류 발생 (ERR-8315): " + error.message));
        }
      }, { canvas: true } // canvas: true, meta: true 
    );
  });
}
