// noinspection DuplicatedCode
// 아쉽게도 vue 2 에서는 tinymce 5 가 최대버전 이다. - https://www.tiny.cloud/docs/configure/integration-and-setup/
import {RawEditorOptions} from 'tinymce';

// ------------------------------------------------------------------------------------
// -- self-host 시작 - https://wiki.imdgo.com/pages/viewpage.action?pageId=164299009
// ------------------------------------------------------------------------------------
import "tinymce/tinymce";                // self-host 기본 - npm i tinymce@5.10.3 # 최신버전 확인 - https://www.npmjs.com/package/tinymce?activeTab=versions
import 'tinymce/models/dom/model';       // v6 이후 self-host 필수
import 'tinymce-i18n/langs/ko_KR'        // 한글 메뉴  - npm i tinymce-i18n
import "tinymce/icons/default"           // (필수) 기본 아이콘
import "tinymce/themes/silver"           // (필수) 기본 테마
// -- 스킨은 공홈 가이드는 이렇게 하라고 하지만 이러면 이미지 리사이즈 안된다.
// -- 참고 : https://wiki.imdgo.com/pages/viewpage.action?pageId=164300320
// import "tinymce/skins/ui/oxide/skin.min.css";      // ui 스킨 1/2
// import "tinymce/skins/ui/oxide/content.min.css";   // ui 스킨 2/2
// -- 플러그인
import './plugin_editimage.min.js'      // v7 용 이미지 편집 툴 - https://wiki.imdgo.com/pages/viewpage.action?pageId=164300441
import 'tinymce/plugins/table'          // 테이블
import 'tinymce/plugins/media'          // 미디어 삽입
import 'tinymce/plugins/fullscreen'     // 전체화면
import 'tinymce/plugins/code'           // 본문을 html 코드로 보기/편집
import 'tinymce/plugins/searchreplace'  // 에디터 내의 검색/치환 기능
import 'tinymce/plugins/link'           // 링크 삽입 수정 버튼
import 'tinymce/plugins/lists'          // 순서없는 리스트, 순서있는 리스트
import 'tinymce/plugins/autolink'       // link 자동으로 걸어주는 기능
import 'tinymce/plugins/image'          // 필수 - 삽입한 이미지 클릭 후 옵션 조정이 필요하다
import 'tinymce/plugins/wordcount'      // 하단에 단어 수 표시
import 'tinymce/plugins/codesample'
// -- 미사용 플러그인
// import 'tinymce/plugins/help'         // 툴바에 help 버튼을 달 수 있는듯. 무쓸모. 써봤자 404 파일 2개 발생함. 
// import 'tinymce/plugins/save'         // 
// import 'tinymce/plugins/visualblocks' // 
// import 'tinymce/plugins/anchor'       // 
// import 'tinymce/plugins/preview'      // 
// import 'tinymce/plugins/charmap'      // 특수문자
// import 'tinymce/plugins/advlist'      // 순서없는 리스트, 순서있는 리스트
// ------------------------------------------------------------------------------------
// -- self-host 종료
// ------------------------------------------------------------------------------------

export const tinymce_default_init: RawEditorOptions = {
  base_url: '/assets/tinymce/7', // TinyMCE의 기본 URL - 스킨경로 떄문에 필요하다 - https://wiki.imdgo.com/pages/viewpage.action?pageId=164300320
  // http://localhost:3000/assets/tinymce/5/skins/ui/oxide/skin.min.css
  // skin: false, // 스킨 이름을 false로 설정하여 기본 스킨 비활성화
  
  content_style: 'p {margin-block-start: 0; margin-block-end: 0; color: #606D81; font-size: 14px;}; table { border: 1px}; img {max-width: 100%;};',
  content_css: '/css/editor-ctx.css', // 여러개 지정시 - https://www.tiny.cloud/docs/tinymce/5/content-appearance/#_using_multiple_stylesheets_example
  body_class: 'editor-ctx', // 에디터 본문 클래스 추가
  
  language: 'ko_KR', // language_url: './tinymce/langs/ko_KR.js',
  height: 500,
  
  menubar: false,   // statusbar: false,
  plugins: // 전체 플러그인 - https://wiki.imdgo.com/pages/viewpage.action?pageId=164300341
    ['wordcount', 'image', 'autolink', 'lists', 'link', 'searchreplace', 'code', 'fullscreen', 'media', 'table', 'code', 'editimage'
    , 'codesample'  // 코드샘플 은 java, c, css 등등 개발코드 넣는기능기라 상황보고 넣자.
    // ', save' // save 플러그인 사용시 init 에 save_onsavecallback: () => { this.$emit('save', this.getContent()) }, 를
                //  추가해서 사용하는 방법이 있지만 keydown 이벤트가 편한듯 하다.
    // ' advlist, charmap,  preview,  anchor, visualblocks, help',   
  ],
  toolbar: '' + // https://www.tiny.cloud/docs/tinymce/latest/available-toolbar-buttons/
    ' styles | bold italic underline strikethrough | forecolor backcolor removeformat |' +
    ' image-imd media a11ycheck link code' +
    ' codesample' +   // 코드샘플 은 java, c, css 등등 개발코드 넣는기능기라 상황보고 넣자.
    ' fullscreen | table | alignleft aligncenter alignright alignjustify | numlist bullist ' +
  '', // image basicDateButton

  // contextmenu: false, // 우클릭메뉴 비활성화 - 이렇게 하면 원래의 우클릭 기능이 자동하여 복사하기, 붙여넣기 기능등이 메뉴에 나온다.
  // contextmenu: isMobile ? false : "paste | link image inserttable | cell row column deletetable", // 마우스 우클릭시 붙여넣기등 다양한 메뉴 나오게
  contextmenu: "paste | link image inserttable | cell row column deletetable", // 마우스 우클릭시 붙여넣기등 다양한 메뉴 나오게
  cleanup: true, // HTML을 정리하고 표준화하는 기능을 활성화
  fontsize_formats: '12px 14px 16px 18px 20px 22px 24px 28px 32px 36px 48px 56px 72px',
  lineheight_formats: '0.5 0.8 1 1.2 1.5 1.75 2 2.5 3 4 5',
  branding: false, // 하단 브랜딩 로고 제거
  // resize: 'both', // (기본 true) 편집기 창 크기 조정가능여부. false,'both' https://www.tiny.cloud/docs/tinymce/6/editor-size-options/#controlling-user-editor-resizing
  elementpath: false, // 하단 status 바에 마크업 경로표시 기능 ex) table > tr > td
}
