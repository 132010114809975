<template>
  <q-bar dense class="i-bar imd-i-bar">
<!--    <div style="font-size: 12px;">•</div>-->
    <div class="i-bar-title" :class="titleClass" v-html="title" style="white-space: nowrap"/>
    <div v-html="cmt" class="ellipsis imd-i-bar-cmt"/>
    <slot name="left"/>
    <q-space />
    <slot />
  </q-bar>
</template>

<script>
export default {
  props: {
    title: {type: String},
    cmt:   {type: String},
    icon:  {type: String},
    titleClass: {},
  }
}
</script>

<style scoped>
.i-bar-title { font-weight: bold;}
</style>