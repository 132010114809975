// noinspection DuplicatedCode

import axios from "axios";

/** 편집기 이미지 파일 타입 */
export interface UploadImageFileType {
  name : string
  size : number
  type : string
  blob : Blob
}

/** <h1>이미지 서버 업로드</h1><pre>
 * 업로드 성공시 업로드된 img_src url 을 반환하고 오류시 exception 발생한다.
 * </pre> */
export async function imageServerUpload(fitem: UploadImageFileType): Promise<string> {

  console.log(`[ImageUploader] name = ${fitem.name}, size = ${fitem.size}, type = ${fitem.type}`)

  if (!fitem.blob || fitem.size === undefined || fitem.size < 1) {
    throw Error(`이미지 파일을 업로드 할 수 없습니다.

 name = ${fitem.name}
 size = ${fitem.size}
 type = ${fitem.type}`)}

  // -- 이미지 width, height 구하기
  // const bitmap = await createImageBitmap(fitem.blob)
  // const width = bitmap.width
  // const height = bitmap.height
  
  let _form = new FormData();
  _form.append('upload', fitem.blob, fitem.name);

  const res = await axios.post("/api/editor/upload", _form, { headers: { 'Content-Type': 'multipart/form-data' }});
  // console.log(" - res.data = ", res?.data)
  console.log("[ImageUploader] uploaded path = ", res?.data?.path)
  if (res?.data?.path) {
    return res?.data?.path  
  } else {
    throw new Error("업로드 실패 " + fitem.name)
  }

}
