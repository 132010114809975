import {
  QTablePaginationType,
  STableApiDetailFuncType,
  STableApiDetailType,
  STableApiListFuncType,
  STableApiListType,
  STableListParamFuncType,
  STablePageingType
} from "src/components-ui/stable/types_stable";
import dayjs from "dayjs";

export const pageingDefault = { // 페이징 기본값
  pageNo: 1,
  pageSize : 10,
  orderBy: undefined,
  orderDesc: true,
} as STablePageingType

// api 요청 기본 함수 (목록)
export const default_apiListFunc: STableApiListFuncType = async (url: string, params) =>
  await T.apiPost<STableApiListType>(url, params)

// api 요청 기본 함수 (상세)
export const default_apiDetailFunc: STableApiDetailFuncType = async (url, params) =>
  (await T.apiPost(url, params)).item as STableApiDetailType

export const default_listParamFunc : STableListParamFuncType = (pageing, innerData) =>
  ({...pageing})

// export const default_listParamFunc : STableListParamFuncType = (pageing, innerData) => {
//   console.log("xxxxxxxxxx= ", JSON.stringify(pageing))
//   return {...pageing}
// }

function cell_style(col, option) {
  const ret:any = {}
  if (col.nowrap || option.nowrap) ret['white-space'] = 'nowrap';
  if (col.width   ) ret.width = col.width;
  if (col.ewidth) { ret.minWidth = col.ewidth }
  else if (col.minWidth) {ret.minWidth = col.minWidth }
  return ret
}

const s_table_format = {
  comma : T.comma,
  sex   : v => v === 'male' ? '남' : v === 'female' ? '여' : v,
  bitYN : v => v == '1' ? 'Y' : v == '0' ? 'N' : v,
  money : v => T.commaWon(v),
  dateYMD : v => T.isNotEmpty(v) && v.length >= 10 && dayjs(v).format("YYYY.MM.DD") || ""
}

/**
 * items 이 첫번째 row 의 key 들로 header 를 만든다.
 * @param items
 * @return headers
 */
export const makeHeadersUseItems = items => T.isEmptyArray(items) ? null : Object.keys(items[0]).map(k => ({
  name : k,
  label: k,
  align: 'center'
}))

/**
 * 헤더에 포멧을 적용한다.
 */
export const applyHeaderFormats = (headers, formats, log) => {
  if (T.isEmptyArray(headers)) return headers // 비었으면 일 안한다.

  const hasFormats = _.isPlainObject(formats) // formats map 이 있나 ?
  headers.forEach(h => {

    // formats 이 유효하다면 적용 ( 물론 key 가 있는 것만 )
    hasFormats && T.NF(formats[h.name], f => h.format = f)

    // h.format 이 문자열인 경우 처리
    if (typeof h.format === 'string') {
      if (T.isBlank(h.format)) // 빈값이면 삭제하고 스탑
        return delete h.format;

      // 사전정의된 형태인 경우
      // log.debug('문자열 format --- (' + h.name + ") : (" + h.format + ")");
      const func = s_table_format[h.format]
      if (typeof func === 'function') {
        h.format = func
      } else {
        log.warn("headerParseAndApplyFormat() 정의되지 않은 header format :", h.format)
        delete h.format
      }
    }
  })

  return headers
}

/**
 * 헤더에 각종 속성( view, sortable, field, style )을 설정하고 헤더name index map 도 만든다. <pre>
 *   작업내용 : 각 헤더에 대해서
 *     1. 필터링 : view 속성이 없거나 만약 있다면 true 인 경우에만 살린다.
 *     2. sortable 이 true가 아니라면 false로 설정한다.
 *     3. field 가 없으면 name 에서 복사한다.
 *     4. cell style 을 설정한다.
 *     5. headerIndex 를 만든다 { [name] : index }
 *   호출하는곳 :
 *     1. setup() - 처음 한번
 *     2. state.innerData.headers 감시하다가 변경되는 경우
 * </pre>
 * @param headers
 * @param nowrap
 * @param from
 * @param log
 */
export const applyHeaderAttrs = (headers, nowrap, from, log) => {
  log.debug("_copyHeaders() call -- from", from) // 1.created() 2.watch(data innerData.headers)
  if (headers) {
    const mHeaders = headers
      .filter(i => T.isNU(i.view) || i.view === true || i.view === 'true' || i.view === '1' || i.view === 'Y')
      .map(e => {
        e.sortable = e.sortable || e.sortable === "true" || false
        if (T.isNU(e.field)) e.field = e.name
        e.style = cell_style(e, {nowrap: nowrap})
        if (e.width) e.headerStyle = {width: e.width} // q-table 헤더 스타일 width 만 적용
        return e
      })
    const headerIndex = headers.reduce((t, v, i)=>((t[v.name]=i),t),{})
    log.debug("_copyHeaders() result 1 mHeaders =", mHeaders, ", headerIndex =", headerIndex)
    return { mHeaders, headerIndex }
  } else {
    log.debug("_copyHeaders() result 2 mHeaders = EMPTY")
    return { mHeaders: [], headerIndex: {} }
  }
}


/** [호환성-어뎁터] <q-table pagination 어뎁터 */

/** stable의 페이징정보를 qtable 페이징형태로 변환 */
export const convert_stablePageing_to_qtablePagination = (pageing:STablePageingType, onepage= false) : QTablePaginationType => {
  const pagination = {
    sortBy      : pageing.orderBy,
    descending  : pageing.orderDesc ?? true,
    page        : pageing.pageNo,
    rowsPerPage : pageing.pageSize,
  } as QTablePaginationType

  // if (!props.onepage) {
  if (!onepage) { // onepage 가 아닐때는 서버모드로 작동한다.
    // <q-table>은 options.rowsNumber 키가 있으면 서버모드로 작동하며
    // 자체 정렬기능이 작동하지 않는다. ( 당연한가 ? )
    // 그래서 onepage가 아닌경우 서버용으로 셋팅하며
    // 정렬은 서버호출로 직접 처리해야한다.
    pagination.rowsNumber = pageing.totalCount || 1
    // 즉 rowsNumber 를 설정하지 않으면 자체 정렬 기능이 정상 작동한다.
  } else { // onepage 일때는 rowsPerPage 를 0으로 해야 전체목록을 보여준다.
    pagination.rowsPerPage = 0
  }
  return pagination
}

/** qtable의 페이징 정보를 stable형태로 변환 */
export const convert_qtablePagination_to_stablePageing = (pagination:QTablePaginationType) : STablePageingType => {
  // { "sortBy": "title", "descending": false, "page": 1, "rowsPerPage": 5 }
  return {
    orderBy  : pagination.sortBy,
    orderDesc: pagination.descending,
    pageNo   : pagination.page,
    pageSize : pagination.rowsPerPage,
  } as STablePageingType
}


