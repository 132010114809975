/**
 * 로그인 상태를 조회하고 필수체크를 한다.
 */

import {onBeforeMount, ref,} from 'vue';
import {EnvType} from 'src/lib/types_env'
import {getRouterByInst} from "lib/co-utils";
import VueRouter from "vue-router";
import {UserInfo} from "@/types/login";
import {useSessionStore} from "@/store_pinia/session";
import Log from "lib/tools/tools_log";
// import {storeToRefs} from "pinia";
// import {storeToRefs} from "pinia";


// const {loginStatus} = storeToRefs(sessionStore)

// const log = T.newLogger("co-login")
const log = Log.getLogger("co-login", 'debug')

/** 로그인시 입력하는 폼 데이터 */
export interface LoginForm {
  /** 로그인 아이디 */
  id: string
  /** 로그인 비밀번호 */
  pw: string
}

/** 로그인응답 */
export interface LoginResponse {
  // 로그인 여부
  login: boolean
  // 사용자 정보
  user?: UserInfo,
  // 세션 남은시간 (초)
  max_session_time?: number
  // 디바이스 정보
  device?: any
  // mtype, brand, com 정보
  _e?: any
}

const fetch = (u:string) => u


/** 로그인 상태 조회 */
export const apiGetLoginStatus = async (from = '') => {
  // log.info("apiGetLoginStatus - 1 - " + from)
  const sessionStore = useSessionStore()
  // 0.5초 이내 재요청은 재활용. 단 로그인 되어 있을때
  const now = new Date().getTime()
  // log.debug(`now = ${now}`)
  // log.debug(`sessionStore.isLogin = ${sessionStore.isLogin}`)
  // log.debug(`sessionStore.loginLastGet = ${sessionStore.loginLastGet}`)
  // log.debug(`now - sessionStore.loginLastGet = ${now - sessionStore.loginLastGet}`)
  if (sessionStore.isLogin && now - sessionStore.loginLastGet < 500) {
    return sessionStore.loginStatus 
  }

  // log.info("apiGetLoginStatus - 2")
  const ret = await T.apiGet<LoginResponse>(fetch("/api/login/status"));
  sessionStore.loginStatus = ret;
  sessionStore.loginLastGet = now
  // log.info("apiGetLoginStatus - 3")
  return ret;
}
  

/**
 * env 조회
 * @param api_referer beforeEnter 에서 사용시에는 현재 주소가 아닌 내가 가려고 하는 주소에서의 ENV가 필요하기 때문에 반드시 입력해야 한다.<pre>
 * ex) api_referer = location.href?.replace(REGEXP_URL에서_path만_제외, "$1") + to.path
 * </pre> */
export const apiGetEnv = async (api_referer ?: string) : Promise<EnvType> => {
  const data = {} as {api_referer ?: string}
  if (api_referer) data.api_referer = api_referer
  // return (await T.apiGet("/v1/api/login/env", data)).item;
  return (await T.apiGet("/api/login/env.do", data)).item;
}

export function co_login() {

  const sessionStore = useSessionStore()

  /** 로그인 정보 */
  const login_info = ref<LoginResponse>();

  /** 로그인 상태확인 */
  const getLoginStatus = async (from = '') =>
    // login_info.value = await T.apiGet("/v1/api/login/status") as LoginResponse
    login_info.value = await apiGetLoginStatus('co-login.ts getLoginStatus > '+from)

  /** 필수 로그인 */
  const checkLogin = async (router?: VueRouter) => {
    const ret = await getLoginStatus('co-login.ts checkLogin');
    if (!ret.login) { // 로그인이 안되어있는 경우
      alert("로그인이 필요한 페이지 입니다.");
      const myRouter = router ?? getRouterByInst();
      log.debug("myRouter = ", myRouter)
      myRouter && await myRouter.push("/login");
      return false
    }
    return true
  }

  /** 로그인 실행 */
  const doLogin = async (loginform: LoginForm) => {
    await T.apiPost(fetch("/api/login/login"), loginform)
    await getLoginStatus('[co-login.ts] doLogin')
    T.noti_success("로그인 되었습니다.")
    return true
  }

  /** 로그아웃 실행 */
  const doLogout = async () => {
    await T.apiGet("/api/login/logout")
    // await getLoginStatus()
    sessionStore.loginStatus = null;
    sessionStore.loginLastGet = 0;
    T.noti_success("로그아웃 되었습니다.")
  }

  /** 자동로그인 */
  const autoLogin = async (id) => {
    const ret = await T.apiGet("/_login/" + id, {type: 'json'})
    await getLoginStatus('[co-login.ts] autoLogin')
  }

  return {
    /** 로그인 상태 */
    // login_info: readonly(login_info), // TODO CHECK :: 이렇게 해야 하는데 ide가 인식을 못한다;;;
    login_info: login_info, // 로그인정보는 보는것만 가능하도록

    /** 기능 */
    getLoginStatus, checkLogin,
    doLogin, doLogout,
    autoLogin,
  }
  // const { login_info, checkLogin, getLoginStatus, doLogin, doLogout } = co_login(context)
}

export default co_login


